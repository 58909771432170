.inputName,
.inputSurname,
.inputTel,
.inputBirthdate,
.checkSex {
  height: 48px;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Primary-300, #ba9e85);
  background: var(--Primary-100, #fafbfb);
  color: var(--Primary-500, #2e2821);
  font-family: Mont;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.inputTel {
  width: 350px;
  min-width: 280px;
  max-width: 350px;
}

.inputName:focus,
.inputSurname:focus,
.inputTel:focus,
.inputBirthdate:focus,
.checkSex:focus {
  border-radius: 8px;
  border: 1px solid var(--Primary-400, #5d4f42);
  background: var(--Primary-100, #fafbfb);
  outline: none;
}

.inputName::placeholder,
.inputSurname::placeholder,
.inputTel::placeholder,
.inputBirthdate::placeholder {
  color: var(--Primary-500, #2e2821);
  font-family: Mont;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.6;
}

@media (max-width: 320px) {
  .inputTel {
    width: 280px;
  }

  .textFieldName,
  .textFieldSurname,
  .textFieldTel,
  .dateFieldBirthdate,
  .checkFieldSex {
    width: 280px;
  }
}
