.buttonNext {
  display: flex;
  width: 350px;
  min-width: 280px;
  max-width: 350px;
  padding: 14px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--Primary-300, #ba9e85);
  border: none;
  color: var(--Primary-500, #2e2821);
  text-align: center;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.buttonNext:active {
  background: var(--Primary-500, #2e2821);
  color: var(--Primary-200, #d6c5b6);
}

@media (max-width: 320px) {
  .buttonNext {
    width: 280px;
  }
}
