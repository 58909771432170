@import url("fonts/mont/stylesheet.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Mont", sans-serif;
  border: none;
  outline: none;
  overflow: scroll;
}

.mainCard {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  height: 951px;
  width: 100%;
  min-width: 320px;
  max-width: 430px;
  background-color: #fafbfb;
  color: black;
  padding: 0;
}

.inputBirthdate {
  width: 350px;
  min-width: 280px;
  max-width: 350px;
  height: 48px;
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Primary-300, #ba9e85);
  background: var(--Primary-100, #fafbfb);
  color: var(--Primary-500, #2e2821);
  font-family: Mont;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  z-index: 20;
}

.inputBirthdate:focus {
  border-radius: 8px;
  border: 1px solid var(--Primary-400, #5d4f42);
  background: var(--Primary-100, #fafbfb);
  outline: none;
  z-index: 20;
}

.inputBirthdate::placeholder {
  color: var(--Primary-500, #2e2821);
  font-family: Mont;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.6;
}

@media (max-width: 320px) {
  .inputBirthdate {
    width: 280px;
  }
}
