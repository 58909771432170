.checkSex {
  display: flex;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  gap: 0;
  border-radius: 8px;
  border: 1px solid var(--Primary-300, #ba9e85);
  background: var(--Primary-100, #fafbfb);
  color: var(--Primary-500, #2e2821);
  font-family: Mont;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.checkSexActive {
  border: 1px solid transparent;
}

.checkMan,
.checkWoman,
.activeMan,
.activeWoman,
.inactiveWoman,
.inactiveMan {
  position: relative;
  display: flex;
  width: 175px;
  min-width: 140px;
  max-width: 175px;
  height: 48px;
  align-items: center;
}

.checkDefault,
.activeMan,
.activeWoman,
.inactiveWoman,
.inactiveMan {
  padding: 16px;
}

.checkMan.checkDefault {
  border-right: 1px solid var(--Primary-300, #ba9e85);
}

.activeMan,
.activeWoman {
  background: var(--Primary-200, #d6c5b6);
  transition: background-color -0.1s ease-in-out;
}

.activeMan,
.inactiveMan {
  border-radius: 8px 0px 0px 8px;
}

.activeWoman,
.inactiveWoman {
  border-radius: 0px 8px 8px 0px;
}

.inactiveWoman,
.inactiveMan {
  transition: background-color -0.1s ease-in-out;
}

.inactiveMan::after,
.inactiveWoman::after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border: 1px solid #2e2821;
  z-index: 1;
}

.activeMan::after,
.activeWoman::after {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border: 2px solid #d6c5b6;
  z-index: 0;
}

.activeMan::after {
  border-right: 2px solid transparent;
}

.activeWoman::after {
  border-left: 2px solid transparent;
}

.activeMan::after,
.inactiveMan::after {
  border-radius: 8px 0px 0px 8px;
}

.inactiveWoman::after,
.activeWoman::after {
  border-radius: 0px 8px 8px 0px;
}

@media (max-width: 320px) {
  .checkFieldSex {
    width: 280px;
  }
  .checkMan,
  .checkWoman {
    width: 140px;
  }
}
