.infoTextFields {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
}

@media (max-width: 320px) {
  .infoTextFields {
    width: 280px;
  }
}
