@font-face {
    font-family: 'Mont';
    src: url('Mont-Black.eot');
    src: local('Mont Black'), local('Mont-Black'),
        url('Mont-Black.eot?#iefix') format('embedded-opentype'),
        url('Mont-Black.woff2') format('woff2'),
        url('Mont-Black.woff') format('woff'),
        url('Mont-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-ThinItalic.eot');
    src: local('Mont Thin Italic'), local('Mont-ThinItalic'),
        url('Mont-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Mont-ThinItalic.woff2') format('woff2'),
        url('Mont-ThinItalic.woff') format('woff'),
        url('Mont-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-ExtraLight.eot');
    src: local('Mont ExtraLight'), local('Mont-ExtraLight'),
        url('Mont-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Mont-ExtraLight.woff2') format('woff2'),
        url('Mont-ExtraLight.woff') format('woff'),
        url('Mont-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-Bold.eot');
    src: local('Mont Bold'), local('Mont-Bold'),
        url('Mont-Bold.eot?#iefix') format('embedded-opentype'),
        url('Mont-Bold.woff2') format('woff2'),
        url('Mont-Bold.woff') format('woff'),
        url('Mont-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-HeavyItalic.eot');
    src: local('Mont Heavy Italic'), local('Mont-HeavyItalic'),
        url('Mont-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('Mont-HeavyItalic.woff2') format('woff2'),
        url('Mont-HeavyItalic.woff') format('woff'),
        url('Mont-HeavyItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-BlackItalic.eot');
    src: local('Mont Black Italic'), local('Mont-BlackItalic'),
        url('Mont-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Mont-BlackItalic.woff2') format('woff2'),
        url('Mont-BlackItalic.woff') format('woff'),
        url('Mont-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-ExtraLightItalic.eot');
    src: local('Mont ExtraLight Italic'), local('Mont-ExtraLightItalic'),
        url('Mont-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Mont-ExtraLightItalic.woff2') format('woff2'),
        url('Mont-ExtraLightItalic.woff') format('woff'),
        url('Mont-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-Heavy.eot');
    src: local('Mont Heavy'), local('Mont-Heavy'),
        url('Mont-Heavy.eot?#iefix') format('embedded-opentype'),
        url('Mont-Heavy.woff2') format('woff2'),
        url('Mont-Heavy.woff') format('woff'),
        url('Mont-Heavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-SemiBold.eot');
    src: local('Mont SemiBold'), local('Mont-SemiBold'),
        url('Mont-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Mont-SemiBold.woff2') format('woff2'),
        url('Mont-SemiBold.woff') format('woff'),
        url('Mont-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-RegularItalic.eot');
    src: local('Mont Regular Italic'), local('Mont-RegularItalic'),
        url('Mont-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('Mont-RegularItalic.woff2') format('woff2'),
        url('Mont-RegularItalic.woff') format('woff'),
        url('Mont-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-BoldItalic.eot');
    src: local('Mont Bold Italic'), local('Mont-BoldItalic'),
        url('Mont-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Mont-BoldItalic.woff2') format('woff2'),
        url('Mont-BoldItalic.woff') format('woff'),
        url('Mont-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-SemiBoldItalic.eot');
    src: local('Mont SemiBold Italic'), local('Mont-SemiBoldItalic'),
        url('Mont-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Mont-SemiBoldItalic.woff2') format('woff2'),
        url('Mont-SemiBoldItalic.woff') format('woff'),
        url('Mont-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-LightItalic.eot');
    src: local('Mont Light Italic'), local('Mont-LightItalic'),
        url('Mont-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Mont-LightItalic.woff2') format('woff2'),
        url('Mont-LightItalic.woff') format('woff'),
        url('Mont-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-Thin.eot');
    src: local('Mont Thin'), local('Mont-Thin'),
        url('Mont-Thin.eot?#iefix') format('embedded-opentype'),
        url('Mont-Thin.woff2') format('woff2'),
        url('Mont-Thin.woff') format('woff'),
        url('Mont-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-Regular.eot');
    src: local('Mont Regular'), local('Mont-Regular'),
        url('Mont-Regular.eot?#iefix') format('embedded-opentype'),
        url('Mont-Regular.woff2') format('woff2'),
        url('Mont-Regular.woff') format('woff'),
        url('Mont-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('Mont-Light.eot');
    src: local('Mont Light'), local('Mont-Light'),
        url('Mont-Light.eot?#iefix') format('embedded-opentype'),
        url('Mont-Light.woff2') format('woff2'),
        url('Mont-Light.woff') format('woff'),
        url('Mont-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

