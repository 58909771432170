.bonusCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 17px;
  color: var(--Primary-400, #5d4f42);
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.anketa,
.discountText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 382px;
  height: 31px;
  color: var(--Primary-400, #5d4f42);
  text-align: center;
  font-family: Mont;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.discountText {
  text-align: center;
  width: 376px;
  height: 51px;
}

.labelName {
  color: var(--Primary-500, #2e2821);
  font-family: Mont;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.labelStar {
  color: var(--Accent, #b90e17);
  font-family: Mont;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.textFieldName,
.textFieldSurname,
.textFieldTel,
.dateFieldBirthdate,
.checkFieldSex {
  display: flex;
  width: 350px;
  min-width: 280px;
  max-width: 350px;
  height: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.checkManText,
.checkWomanText {
  opacity: 0.6;
  cursor: pointer;
}
.activeManText,
.activeWomanText {
  opacity: 1;
}

@media (max-width: 320px) {
  .anketa,
  .discountText {
    width: 280px;
  }

  .discountText {
    text-align: left;
  }

  .infoTextFields {
    width: 280px;
  }

  .textFieldName,
  .textFieldSurname,
  .textFieldTel,
  .dateFieldBirthdate,
  .checkFieldSex {
    width: 280px;
  }
}
