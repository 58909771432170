.image {
  display: inline-block;
  width: 100%;
  min-width: 320px;
  max-width: 430px;
  height: 253px;
  border-radius: 0px 0px 30px 30px;
  background: url("../../public/images/hero.png"),
    lightgray 50% / cover no-repeat;
  background-size: cover;
}

@media (max-width: 320px) {
  .image {
    height: 180px;
    width: 320px;
  }
}
